* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  background-color: #f1fffe;
  font-family: "Montserrat", sans-serif;
}

body::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none;
  scrollbar-width: none;
  font-size: 1.1rem;
}

header {
  background-color: #d4f0ef;
  height: 110px;
  display: flex;
  align-items: center;
  padding-block: 10px;
}

header .site-logo {
  color: black;
  margin-right: auto;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 25px;
}


.menu-button {
  display: none;
  font-size: 1.5em;
  background: none;
  border: none;
  cursor: pointer;
}

/* Standaard menu styling */
nav.menu {
  display: flex;
  gap: 15px;
}

/* Menu styling voor mobiele apparaten */
@media (max-width: 768px) {
  nav.menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px; /* Afhankelijk van je header hoogte */
    right: 10px;
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 10px;
  }

  nav.menu.open {
    display: flex;
  }

  .menu-button {
    display: block;
  }

  nav.menu a {
    padding: 10px;
    text-decoration: none;
    color: #000;
  }

  nav.menu a:hover {
    background-color: #ddd;
  }
}

header a {
  text-decoration: none;
  color: #4d4d4d;
  font-weight: 600;
  padding: 5px 10px;
}

header a:hover {
  color: #161616;
  text-decoration: underline;
}

details {
  margin-top: 0px;
}

h4 {
  margin-bottom: 0px;
}

.vragen{
  margin-top: 0px;
  margin-bottom: 0px;
}

nav {
  margin-right: 10px;
}

.link-button {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  border: none;
  padding: 0.75rem 1.375rem;
  color: initial;
  font-weight: 700;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.1s ease-in-out;
}

.link-button:hover {
  transform: translate(1px, 1px);
}

/*** HOME PAGE ***/
.home-container {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.46), rgba(0, 0, 0, 0.46)),
    url('./images/writer2.jpg') no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 350px;
  color: white;
  padding: 45px 23px;
}

.home-container h1 {
  font-weight: 700;
  font-size: 2.25rem;
  line-height: 42px;
}

.home-container p {
  line-height: 24px;
}

.home-container a {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  background-color: #7fbebc;
  border: none;
  width: 100%;
  margin-top: 27px;
  padding-block: 0.75rem;
  color: white;
  font-weight: 700;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.1s ease-in-out;
}

.home-container a:hover {
  transform: translate(1px, 1px);
}

/* Default styles for larger screens */
.quote-container {
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding: 80px;
  text-align: center;
  width: 100%;
}

.quote {
  border: 2.5px solid #93cfcd; 
  border-radius: 10px;
  flex: 1;
  margin-right: 30px;
  font-style: italic;
  padding-left: 20px;
  padding-right: 10px;
  text-align: left;
}

.quote:last-child {
  margin-right: 0; 
}

/* Media query for smaller screens */
@media only screen and (max-width: 600px) {
  .quote-container {
    display: flex;
    flex-direction: column; 
    padding: 20px; 
  }

  .quote {
    margin-right: 0; 
    margin-bottom: 20px; 
    padding-left: 10px; 
    padding-right: 10px;
  }

  .quote:last-child {
    margin-bottom: 0; 
  }
}

@media only screen and (max-width: 600px) {
  header {
    padding-block: 5px; 
    
  }

  header nav {
    display: flex;
    justify-content: space-between; 
    gap: 5px; 
  }

  header a {
    font-size: 0.86rem; 
    padding: 3px 8px; 
  }
}


/*** ABOUT PAGE ***/

.about-page-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #f1fffe;
}

.about-hetty-image,
.about-anna-image {
  width: 100%; 
  height: auto; 
  max-width: 300px; 
  max-height: 400px; 
  object-fit: cover; 
  margin: 15px;
  border-radius: 16px;
}

.about-page-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: white;
  padding: 20px;
}

.about-page-content {
  padding-inline: 23px;
  color: #161616;
  margin-bottom: 30px;
}

.about-page-content h1 {
  line-height: 38px;
}

.about-page-content p {
  line-height: 22px;
}

.about-page-cta {
  background-color: #d0e8e7;
  color: #161616;
  padding-inline: 32px;
  padding-bottom: 32px;
  margin-inline: 27px;
  border-radius: 5px;
  margin-bottom: 40px;
  border: 3.5px solid #9cd6d5;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.about-page-cta h2 {
  margin: 0;
  padding-block: 37px;
}

.about-page-cta .link-button {
  background-color: #161616;
  color: white;
  border-radius: 10px;
}

.image-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bullet-point {
  display: block;
  margin-left: 10px;
  padding-left: 10px;
  line-height: 1.5;


  @media only screen and (max-width: 600px) {
    margin-left: 10px;
    padding-left: 0;
    padding-bottom: 15px;
  }
}

.indent {
  display: block;
  margin-left: 20px;
  margin-bottom: 0;
}

.with-border {
  border: 3.5px solid #5e9493;
}

.with-image-border {
  border: 3.5px solid #c4e1e0;
}

/*** BLOG PAGE ***/

.alcohol-blog {
  background-color: #f1fffe;
  padding-left: 45px;
  padding-right: 45px;
  padding-top: 25px;
}

.hr-container {
  width: 30%;
  margin: 18px auto 0px;
}

.custom-hr {
  border: none;
  border-top: 3px solid #5e9493;
  padding-top: 10px;
}

.title-blog-alcohol {
  margin-top: 15px;
}

.alcohol-letter {
  padding-top: 20px;
  padding-bottom: 45px;
  padding-left: 55px;
  padding-right: 55px;
}

@media only screen and (max-width: 600px) {
  .alcohol-blog {
    padding-left: 23px;
    padding-right: 23px;
    padding-top: 10px; 
    padding-bottom: 30px; 
  }

  .alcohol-letter {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 20px;
    padding-bottom: 35px;
  }

  .alcohol-letter em br {
    content: "\00a0"; 
    display: block;
    margin-bottom: 10px; 
  }
}

.contact-info {
  text-align: center;
}

.names{
  font-style: normal;
}

/*** REVIEWS PAGE ***/

.review-page-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: white;
  padding: 20px;
  width: 100%;

}

.review-page-content {
  padding-inline: 23px;
  color: #161616;
  margin-bottom: 30px;
}

.review-page-content h1 {
  line-height: 38px;
}

.review-page-content p {
  line-height: 22px;
}

.reviewName {
  font-style: italic;
}


.about-page-content,
.alcohol-blog,
.information-content,
.review-page-content{
  padding-left: 40px;  /* Verplaats de tekst naar rechts */
  padding-right: 60px; /* Verplaats de tekst naar links */
}

.ambacht,
.persoonlijk,
.schrijftherapie,
.expats,
.theater{
  border: 2px solid rgb(70, 123, 124);
  border-radius: 10px;
  padding: 20px;
  padding-left: 30px;
  margin-bottom: 15px;
}

.nieuws{
  border: 2px solid rgb(213, 145, 139);
  border-radius: 10px;
  padding: 20px;
  padding-left: 30px;
  margin-bottom: 15px;
  background-color: rgb(238, 208, 205);
}

.ambacht strong,
.persoonlijk strong,
.schrijftherapie strong,
.expats strong,
.theater strong,
.nieuws strong{
  line-height: 1.8;
}

.ambacht p,
.persoonlijk p,
.schrijftherapie p,
.expats p,
.theater p,
.nieuws p{
  margin-right: 7px;
}

.pen{
  margin-bottom: 20px;
}


@media only screen and (max-width: 600px) {
  /* Verklein de padding van de home-container */
  .home-container {
    padding: 20px 15px;  /* Verkleint padding rondom de container */
  }

  /* Verklein de padding van de quote-container */
  .quote-container {
    padding: 15px;
  }

  /* Verklein de padding van about-page-content */
  .about-page-content {
    padding-left: 15px;
    padding-right: 15px;
  }

  .review-page-container{
    padding-left: 15px;
    padding-right: 15px;
  }

  .review-page-content{
    padding-left: 15px;
    padding-right: 15px;
  }
  /* Verklein de padding en margins van about-page-cta */
  .about-page-cta {
    padding-inline: 20px;
    padding-bottom: 20px;
    margin-inline: 15px;
    margin-bottom: 20px;
  }

  /* Verklein de padding van alcohol-blog en alcohol-letter */
  .alcohol-blog {
    padding-left: 15px;
    padding-right: 15px;
  }

  .alcohol-letter {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
    padding-bottom: 25px;
  }



  /* Verklein de padding van .ambacht, .persoonlijk, en .schrijftherapie */
  .ambacht,
  .persoonlijk,
  .expats,
  .theater,
  .schrijftherapie {
    padding: 15px;
    padding-left: 20px;
    margin-bottom: 10px;
    background-color: #ebf6f6;
  }

  .nieuws{
    padding: 15px;
    padding-left: 20px;
    margin-bottom: 10px;
  }

  .bullet-point{
    line-height: 22px;
  }

  .schrijftherapie strong {
    line-height: 22px;
}

.about-page-cta{
  border: 2px solid rgb(72, 69, 69);
}
}

.no-colour-link{
  color: inherit;
  text-decoration: none;
}